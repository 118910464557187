import { notify } from "@kyvg/vue3-notification"
import { extractDataOrFailure, useDYFetch, useDYFetchWithToken } from "~~/modules/nuxt3-module-doyac/composables/fetch"
import { type MyScrapList, useGWScrapStore } from "../stores/scrap"
import { useUserStore } from "~~/stores/user"


export interface UserScrap {
  no: number
}

export interface ListResult<T> {
  items: T[]
  totalCount: number
}

export function useGWUserScrapManager() {
  const useScrapStore = useGWScrapStore()
  const userStore = useUserStore()
  const { getMyScrapNos, toggle } = useGWUserScrap()

  const getMyScrapList = async () => {
    if (!userStore.isSignedIn) return

    useScrapStore.scrapList = await getMyScrapNos()
  }

  const _arrayDelete = (deleteValue:number, targetGroupVal: 'teacher' | 'student') => {
    if(useScrapStore.scrapList){
      useScrapStore.scrapList[targetGroupVal] = useScrapStore.scrapList[targetGroupVal].filter((value)=>{
        return value !== deleteValue
      })
    }
  }

  const toggleScrap = async (requestFormNo: number, targetGroupVal: 'teacher' | 'student') => {

    notify({clean:true}) //기존에 있던 내용들 지워줌

    try {
      const res = await toggle(requestFormNo, targetGroupVal)
      const term = res === 'insert' ? '등록' : '삭제'
      notify({
        title: "스크랩 " + term+"완료! 🎉",
        text: term+"했습니다",
      });

      if(useScrapStore.scrapList){ //스크랩스토어에도 해당 내용 반영
        res === 'insert' ? useScrapStore.scrapList[targetGroupVal].push(requestFormNo) : _arrayDelete(requestFormNo, targetGroupVal)
      }
    } catch (e: any) {
      notify({
        group: e.message === '로그인이 필요합니다' ? 'login' : '',
        title: e.message,
        type: 'error'
      });
    }
  }

  return {
    getMyScrapList, toggleScrap
  }
}

export function useGWUserScrap() {

  /**
   * 로그인 중인 해당 유저가 스크랩한 내역들을 가져옴. 스크랩리스트 전문 페이지 확인용이 아닌 요청서 리스트 스크랩 체크용임
   * @returns 
   */
  const getMyScrapNos = async () => {
    const response = await useDYFetchWithToken<MyScrapList>(`/mer/api/userScraps/my-scrap`, { method: 'GET' })
    return extractDataOrFailure(response)
  }

  const insert = async (requestFormNo: number, targetGroupVal: 'teacher' | 'student') => {
    const response = await useDYFetchWithToken(`/mer/api/userScraps`, { method: 'POST', body: { requestFormNo, targetGroupVal } })
    return extractDataOrFailure(response)
  }

  const toggle = async (requestFormNo: number, targetGroupVal: 'teacher' | 'student') => {
    const response = await useDYFetchWithToken<'insert'|'delete'>(`/mer/api/userScraps/toggle`, { method: 'POST', body: { requestFormNo, targetGroupVal } })
    return extractDataOrFailure(response)
  }

  return {
    insert, getMyScrapNos, toggle
  }
}
