import { defineStore } from "pinia"

export interface MyScrapList {
  teacher:number[]
  student:number[]
}

export const useGWScrapStore = defineStore("nuxt3-module-gawe-scrap", () => {
  const scrapList = ref<MyScrapList>()

  return {
    /**
     * 해당 유저가 로그인한 시점에서 본인이 스크랩한 대상들을 가져옴. 
     */
    scrapList
  }
})